/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'sites',
        title: 'Sites',
        type : 'basic',
        icon : 'heroicons_outline:map-pin',
        link : '/sites'
    },
    {
        id   : 'locomotives',
        title: 'Locomotives',
        type : 'basic',
        icon : 'mat_outline:train',
        link : '/locomotives'
    },
    {
        id   : 'inspections',
        title: 'Inspections',
        type : 'basic',
        icon : 'mat_outline:assignment_turned_in',
        link : '/inspections'
    },
    {
        id   : 'defects',
        title: 'Defects',
        type : 'basic',
        icon : 'mat_outline:build',
        link : '/defects'
    },
    {
        id   : 'admin',
        title: 'Administration',
        type : 'aside',
        icon : 'heroicons_outline:adjustments',
        //link : '/admin',
        children: [
            {
                id   : 'admin-users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/admin/users'
            },
            {
                id   : 'admin-sites',
                title: 'Sites',
                type : 'basic',
                icon : 'heroicons_outline:map-pin',
                link : '/admin/sites'
            },
            {
                id   : 'admin-locomotives',
                title: 'Locomotives',
                type : 'basic',
                icon : 'mat_outline:train',
                link : '/admin/locomotives'
            },
            {
                id   : 'admin-files',
                title: 'File Manager',
                type : 'basic',
                icon : 'mat_outline:insert_drive_file',
                link : '/admin/files'
            }
        ]
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
