export const environment = {
    apiUrl: 'https://api.dev.cando-maintenance.com/api',
    msalConfig: {
        auth: {
            clientId: 'b520669f-57ee-4699-987a-a74edd2123c1',
            authority: 'https://login.microsoftonline.com/5eb41cf7-c06b-4232-84b5-b221f1741396/'
        }
    },
    apiConfig: {
        scopes: ['api://b520669f-57ee-4699-987a-a74edd2123c1/access_as_user'],
        uri: 'api://b520669f-57ee-4699-987a-a74edd2123c1'
    }
};