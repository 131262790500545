import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { LocomotiveService } from 'app/modules/locomotives/locomotive.service';

@Injectable({
    providedIn: 'root'
})
export class SearchMockApi
{
    private readonly _locomotives: any[] = [];

    /**
     * Constructor
     */
    constructor(
        private _locomotiveService: LocomotiveService,
        private _fuseMockApiService: FuseMockApiService,
    )
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Search results - GET
        // -----------------------------------------------------------------------------------------------------
        this._locomotiveService.getLocomotivesByUser().subscribe((locomotives: any) => {
            this._locomotives.push(...locomotives);
        });
        
        this._fuseMockApiService.onPost('api/common/search').reply(({request}) => {

            // Get the search query
            const query = cloneDeep(request.body.query.toLowerCase());

            // If the search query is an empty string,
            // return an empty array
            if ( query === '' ) { return [200, {results: []}]; }

            const locoResults = cloneDeep(this._locomotives).filter(loco => 
                loco.name.toLowerCase().includes(query) || loco.site.name.toLowerCase().includes(query)
            );

            // Prepare the results array
            const results = [];

            // If there are contacts results...
            if ( locoResults.length > 0 )
            {
                // Normalize the results
                locoResults.forEach((result) => {

                    // Add a link
                    result.link = 'locomotives/' + result.name;

                    // Add the name as the value
                    result.value = result.name;
                });

                // Add to the results
                results.push({
                    id     : 'locomotives',
                    label  : 'Locomotives',
                    results: locoResults
                });
            }

            // Return the response
            return [200, results];
        });
    }
}
