import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthService
{
    /**
     * Constructor
     */
    constructor(
        private authService: MsalService,
    )
    {
    }

    /**
     * Sign out
     */
    logout(popup?: boolean) {
        if (popup) {
          this.authService.logoutPopup({
            mainWindowRedirectUri: "/"
          });
        } else {
          this.authService.logoutRedirect();
        }
    }

    getActiveAccount() {
        return this.authService.instance.getActiveAccount();
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        let activeAccount = this.authService.instance.getActiveAccount();

        return activeAccount ? of(true) : of(false);
    }


    /**
     * Check the authentication status, and admin role
     */
        checkIsAdmin(): Observable<boolean>
        {
            let activeAccount = this.authService.instance.getActiveAccount();
    
            return activeAccount && activeAccount.idTokenClaims.roles.includes('administrator') ? of(true) : of(false);
        }
}

