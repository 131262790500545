import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Site } from '../sites/admin-sites.types';
import { environment } from 'environments/environment';
import { UpdateUser, UpdateUserSites, User } from './admin-users.types';

@Injectable({
    providedIn: 'root'
})
export class AdminUsersService
{
    // Private
    private _user: BehaviorSubject<User | null> = new BehaviorSubject(null);
    private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
    private _allUsers: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
    private _sites: BehaviorSubject<Site[] | null> = new BehaviorSubject(null);
    private searchString: string;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for user
     */
    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    /**
     * Getter for users
     */
    get users$(): Observable<User[]>
    {
        return this._users.asObservable();
    }

    /**
     * Getter for sites
     */
    get sites$(): Observable<Site[]>
    {
        return this._sites.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get users
     */
    getUsers(): Observable<User[]>
    {
        this.searchString = '';
        return this._httpClient.get<any>(`${environment.apiUrl}/Users`).pipe(
            tap((response) => {
                this._users.next(response.users.sort((a, b) => a.email.localeCompare(b.email)));
                this._allUsers.next(response.users.sort((a, b) => a.email.localeCompare(b.email)));
            })
        );
    }

    /**
     * Search users with given query
     *
     * @param query
     */
    searchUsers(query: string): Observable<User[]>
    {
        this.searchString = query;
        let filtered: User[] = [];

        // Filter users by name
        filtered = this._allUsers.value.filter(u => u.email && u.email.toLowerCase().includes(query.toLowerCase()));
        filtered.sort((a, b) => a.email.localeCompare(b.email));

        this._users.next(filtered);
        return of(filtered);
    }

    /**
     * Get user by id (from memory)
     */
    getUserById(id: string): Observable<User>
    {
        // This is to get the user from the API
        // return this._httpClient.get<User>(`${environment.apiUrl}/Users/${id}`)
        // .pipe(
        //     tap((user) => {
        //         this._user.next(user);
        //     })
        // );

        // Find the user using the id
        const user = this._allUsers.value.find(item => item.userPoolUserId === id);
        this._user.next(user);
        return of(user);
    }

    getDbUserById(id: string): Observable<User>
    {
        return this._httpClient.get<User>(`${environment.apiUrl}/Users/${id}`);
    }






    clearSelectedUser()
    {
        this._user.next(null);
    }



    /**
     * Update user
     *
     * @param user
     */
    updateUser(updateUser: UpdateUser): Observable<User>
    {
        return this._httpClient.put<User>(`${environment.apiUrl}/Users/${updateUser.id}`, updateUser)
            .pipe(
                map((updatedUser) => {
                    
                    // Update the users with the new user
                    var allUsers = this._allUsers.value;

                    // Find the index of the updated site
                    const index = allUsers.findIndex(item => item.id === updatedUser.id);
                    allUsers[index] = updatedUser;

                    this._user.next(updatedUser);

                    this._allUsers.next(allUsers);
                    this.searchUsers(this.searchString);

                    // Return the updated user
                    return updatedUser;
                })
            );

    }

    updateUserSites(userSites: UpdateUserSites): Observable<User>
    {
        return this._httpClient.put<User>(`${environment.apiUrl}/Users/${userSites.id}/sites`, userSites);
    }

    updateUserSettings(updateUser: UpdateUser)
    {
        return this._httpClient.put<User>(`${environment.apiUrl}/Users/${updateUser.id}/settings`, updateUser);
    }

}
