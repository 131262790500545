import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { Locomotive, LocomotiveSlim } from '../admin/locomotives/admin-locomotives.types';
import { environment } from 'environments/environment';
import { AttachmentFile } from '../defects/defects.types';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Injectable({
    providedIn: 'root'
})
export class LocomotiveService
{
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);
    private _locomotivesBySites: BehaviorSubject<any> = new BehaviorSubject(null);
    private _locomotivesByUser: BehaviorSubject<any> = new BehaviorSubject(null);
    private _selectedLocomotive: BehaviorSubject<Locomotive> = new BehaviorSubject(null);
    private _selectedTabLocomotiveTab: BehaviorSubject<number> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _matDialog: MatDialog,
        private _confirmationService: FuseConfirmationService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any>
    {
        return this._data.asObservable();
    }

    get locomotivesBySites$(): Observable<Locomotive[]>
    {
        return this._locomotivesBySites.asObservable();
    }

    get locomotivesByUser$(): Observable<LocomotiveSlim[]>
    {
        return this._locomotivesByUser.asObservable();
    }

    get selectedLocomotive$(): Observable<Locomotive>
    {
        return this._selectedLocomotive.asObservable();
    }

    get selectedLocomotiveTab$(): Observable<number>
    {
        return this._selectedTabLocomotiveTab.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any>
    {
        return this._httpClient.get('api/dashboards/project').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }


    /**
     * Get locomotive by sites
     */

    getLocomotivesBySites(selectedSites: number[]): Observable<Locomotive[]>
    {
        return this._httpClient.post<Locomotive[]>(`${environment.apiUrl}/locomotives/sites`, selectedSites)
        .pipe(
            tap((response: Locomotive[]) => {
                this._locomotivesBySites.next(response);
            })
        );
    }

    getLocomotivesByUser(): Observable<LocomotiveSlim[]>
    {
        return this._httpClient.get<LocomotiveSlim[]>(`${environment.apiUrl}/locomotives/user`)
        .pipe(
            tap((response: LocomotiveSlim[]) => {
                this._locomotivesByUser.next(response);
            })
        );
    }


    // Make sure to call this after the locomotives are loaded (_locomotivesBySites)
    setSelectedLocomotive(name: string): Observable<Locomotive>
    {
        if (name == null || name == '') return of(null);

        var loco = this._locomotivesByUser.value.
        find((locomotive: Locomotive) => locomotive.name === name);

        if (!loco) return of(null);

        return this._httpClient.get<Locomotive>(`${environment.apiUrl}/locomotives/${loco.id}`)
        .pipe(
            tap((response: Locomotive) => {
                this._selectedLocomotive.next(response);
            })
        );

    }

    persistAttachments(fileInput, locomotiveId)
    {
        // use a forkjoin to save all the attachments
        const formData = new FormData();
        formData.append('file', fileInput);
        formData.append('name', fileInput.name);
        formData.append('locomotiveId', locomotiveId.toString());
        return this._httpClient.post<Locomotive>(`${environment.apiUrl}/Locomotives/Attachment`, formData)
        .pipe(tap((loco) => {
            this._selectedLocomotive.next(loco);
        }));
    }

    persistMechanicAttachments(fileInput, locomotiveId)
    {
        // use a forkjoin to save all the attachments
        const formData = new FormData();
        formData.append('file', fileInput);
        formData.append('name', fileInput.name);
        formData.append('locomotiveId', locomotiveId.toString());
        return this._httpClient.post<Locomotive>(`${environment.apiUrl}/Locomotives/MechanicAttachment`, formData)
        .pipe(tap((loco) => {
            this._selectedLocomotive.next(loco);
        }));
    }

    getAttachment(attachmentId: number)
    {
        return this._httpClient.get<AttachmentFile>(`${environment.apiUrl}/Locomotives/Attachment/${attachmentId}`)
        .subscribe((attachment) => {
            this.downloadFile(attachment);
        });
    }

    getMechanicAttachment(attachmentId: number)
    {
        return this._httpClient.get<AttachmentFile>(`${environment.apiUrl}/Locomotives/MechanicAttachment/${attachmentId}`)
        .subscribe((attachment) => {
            this.downloadFile(attachment);
        });
    }

    private downloadFile(attachment: AttachmentFile) {
        const binaryString = window.atob(attachment.fileData);
        const bytes = new Uint8Array(binaryString.length);
        const binaryToBlob = bytes.map((byte, i) => binaryString.charCodeAt(i));
        const blob = new Blob([binaryToBlob], { type: 'application/octet-stream' });
        
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob, attachment.fileName);
            return;
        }
        
        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);
                    
        var link = document.createElement('a');
        link.href = data;
        link.download = attachment.fileName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }

    deleteAttachment(attachmentId: number)
    {
        let ref = this._confirmationService.open({
            title: 'Delete Attachment',
            message: 'Are you sure you want to delete this attachment?',
        });

        
        ref.afterClosed().subscribe((action) => {
            if (action === 'confirmed') {
                this._httpClient.delete<Locomotive>(`${environment.apiUrl}/Locomotives/Attachment/${attachmentId}`)
                .subscribe((loco) => {
                    this._selectedLocomotive.next(loco);
                });
            }
        });
    }

    deleteMechanicAttachment(attachmentId: number)
    {
        let ref = this._confirmationService.open({
            title: 'Delete Attachment',
            message: 'Are you sure you want to delete this attachment?',
        });

        
        ref.afterClosed().subscribe((action) => {
            if (action === 'confirmed') {
                this._httpClient.delete<Locomotive>(`${environment.apiUrl}/Locomotives/MechanicAttachment/${attachmentId}`)
                .subscribe((loco) => {
                    this._selectedLocomotive.next(loco);
                });
            }
        });
    }

    onSelectedTabChanged(tabIndex: number) {
        this._selectedTabLocomotiveTab.next(tabIndex);
    }
}
