import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import moment from 'moment';
const now = DateTime.now();

@Injectable({
    providedIn: 'root'
})
export class NotificationsService
{
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]>
    {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]>
    {
        return this._httpClient.get<Notification[]>(`${environment.apiUrl}/notifications/user/`)
        .pipe(map((response: any[]) => {
            var notifications = response.map((notification) => {
                return {
                    id: notification.id,
                    icon: 'heroicons_solid:mail',
                    //image: notification.image,
                    title: notification.title,
                    description: notification.message,
                    time: moment(notification.created).fromNow(),
                    // link: notification.link,
                    // useRouter: notification.useRouter,
                    read: notification.isRead,
                }
            });
            
            this._notifications.next(notifications);
            return notifications;
        }));
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', {notification}).pipe(
                map((newNotification) => {

                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                })
            ))
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification>
    {
        if (notification.read) {
            return this._httpClient.put<Notification>(`${environment.apiUrl}/notifications/mark-read`,
            {notificationIds: [id]})
            .pipe(tap((response: any) => {
    
                this.notifications$.pipe(take(1)).subscribe(((notifications) => {
                    
                    var index = notifications.findIndex(item => item.id === id);
                    notifications[index].read = true;
                    this._notifications.next(notifications);
    
                    return notifications[index];
                }))
            }));
        } else {
            return this._httpClient.put<Notification>(`${environment.apiUrl}/notifications/mark-unread`,
            {notificationIds: [id]})
            .pipe(tap((response: any) => {

                this.notifications$.pipe(take(1)).subscribe(((notifications) => {
                    
                    var index = notifications.findIndex(item => item.id === id);
                    notifications[index].read = false;
                    this._notifications.next(notifications);

                    return notifications[index];
                }))
            }));
        }
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean>
    {
        return this._httpClient.delete(`${environment.apiUrl}/notifications/${id}`)
        .pipe(tap((response: any) => {
            this.notifications$.pipe(take(1)).subscribe(((notifications) => {
                var index = notifications.findIndex(item => item.id === id);
                notifications.splice(index, 1);
                this._notifications.next(notifications);
                return true;
            }))
        }));
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(notificationIds: number[]): Observable<Notification[]>
    {
        return this._httpClient.put<Notification[]>(`${environment.apiUrl}/notifications/mark-read`,
        {notificationIds})
        .pipe(map((response: any[]) => {
            var notifications = response.map((notification) => {
                return {
                    id: notification.id,
                    icon: 'heroicons_solid:mail',
                    //image: notification.image,
                    title: notification.title,
                    description: notification.message,
                    time: moment(notification.created).fromNow(),
                    // link: notification.link,
                    // useRouter: notification.useRouter,
                    read: notification.isRead,
                }
            });
            
            this._notifications.next(notifications);
            return notifications;
        }));
    }
}
