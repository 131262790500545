/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const project = {
    githubIssues      : {
        overview: {
            'this-week': {
                'new-issues'   : 214,
                'closed-issues': 75,
                'fixed'        : 3,
                'wont-fix'     : 4,
                're-opened'    : 8,
                'needs-triage' : 6
            },
            'last-week': {
                'new-issues'   : 197,
                'closed-issues': 72,
                'fixed'        : 6,
                'wont-fix'     : 11,
                're-opened'    : 6,
                'needs-triage' : 5
            }
        },
        labels  : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        series  : {
            'this-week': [
                {
                    name: 'New issues',
                    type: 'line',
                    data: [42, 28, 43, 34, 20, 25, 22]
                },
                {
                    name: 'Closed issues',
                    type: 'column',
                    data: [11, 10, 8, 11, 8, 10, 17]
                }
            ],
            'last-week': [
                {
                    name: 'New issues',
                    type: 'line',
                    data: [37, 32, 39, 27, 18, 24, 20]
                },
                {
                    name: 'Closed issues',
                    type: 'column',
                    data: [9, 8, 10, 12, 7, 11, 15]
                }
            ]
        }
    },
    taskDistribution  : {
        overview: {
            'this-week': {
                'new'      : 594,
                'completed': 287
            },
            'last-week': {
                'new'      : 526,
                'completed': 260
            }
        },
        labels  : ['API', 'Backend', 'Frontend', 'Issues'],
        series  : {
            'this-week': [15, 20, 38, 27],
            'last-week': [19, 16, 42, 23]
        }
    },
    schedule          : {
        today   : [
            {
                title   : '45 Day Inspection',
                time    : 'Tomorrow',
                location: 'CCGX2122',
                status: 'IN_PROGRESS'
            },
            {
                title: 'Exhaust Inspection',
                time : 'January 28, 2023',
                location: 'CCGX1221',
                status: 'IN_PROGRESS'
            },
            {
                title   : '45 Day Inspection',
                time    : 'January 30, 2023',
                location: 'CCGX2122',
                status: 'NOT_STARTED'
            }
        ],
        tomorrow: [
            {
                title   : 'Marketing Meeting',
                time    : '09:00 AM',
                location: 'Conference room 1A'
            },
            {
                title: 'Public Announcement',
                time : '11:00 AM'
            },
            {
                title: 'Lunch',
                time : '12:10 PM'
            },
            {
                title   : 'Meeting with Beta Testers',
                time    : '03:00 PM',
                location: 'Conference room 2C'
            },
            {
                title: 'Live Stream',
                time : '05:30 PM'
            },
            {
                title   : 'Release Party',
                time    : '07:30 PM',
                location: 'CEO\'s house'
            },
            {
                title   : 'CEO\'s Private Party',
                time    : '09:30 PM',
                location: 'CEO\'s Penthouse'
            }
        ]
    },
    alerts          : {
        today   : [
            {
                title   : 'No GPS Activity',
                time    : '15',
                location: 'CCGX2122',
                status: 'IN_PROGRESS'
            },
            {
                title: 'Bad order',
                time : '55',
                location: 'CCGX1221',
                status: 'IN_PROGRESS'
            },
            {
                title   : 'Cars in shop',
                time    : '128',
                location: 'CCGX2122',
                status: 'NOT_STARTED'
            },
            {
                title   : 'Cars in storage',
                time    : '266',
                location: 'CCGX2122',
                status: 'NOT_STARTED'
            }
        ],
    },
    budgetDistribution: {
        categories: ['Concept', 'Design', 'Development', 'Extras', 'Marketing'],
        series    : [
            {
                name: 'Budget',
                data: [12, 20, 28, 15, 25]
            }
        ]
    },
    weeklyExpenses    : {
        amount: 17663,
        labels: [
            now.minus({days: 47}).toFormat('dd MMM') + ' - ' + now.minus({days: 40}).toFormat('dd MMM'),
            now.minus({days: 39}).toFormat('dd MMM') + ' - ' + now.minus({days: 32}).toFormat('dd MMM'),
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM')
        ],
        series: [
            {
                name: 'Expenses',
                data: [4412, 4345, 4541, 4677, 4322, 4123]
            }
        ]
    },
    monthlyExpenses   : {
        amount: 54663,
        labels: [
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM')
        ],
        series: [
            {
                name: 'Expenses',
                data: [15521, 15519, 15522, 15521]
            }
        ]
    },
    yearlyExpenses    : {
        amount: 648813,
        labels: [
            now.minus({days: 79}).toFormat('dd MMM') + ' - ' + now.minus({days: 72}).toFormat('dd MMM'),
            now.minus({days: 71}).toFormat('dd MMM') + ' - ' + now.minus({days: 64}).toFormat('dd MMM'),
            now.minus({days: 63}).toFormat('dd MMM') + ' - ' + now.minus({days: 56}).toFormat('dd MMM'),
            now.minus({days: 55}).toFormat('dd MMM') + ' - ' + now.minus({days: 48}).toFormat('dd MMM'),
            now.minus({days: 47}).toFormat('dd MMM') + ' - ' + now.minus({days: 40}).toFormat('dd MMM'),
            now.minus({days: 39}).toFormat('dd MMM') + ' - ' + now.minus({days: 32}).toFormat('dd MMM'),
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM')
        ],
        series: [
            {
                name: 'Expenses',
                data: [45891, 45801, 45834, 45843, 45800, 45900, 45814, 45856, 45910, 45849]
            }
        ]
    },
    budgetDetails     : {
        columns: ['type', 'outOfServiceOn', 'daysOutOfService'],
        rows   : [
            {
                id                 : 1,
                type               : 'CCGX1234',
                outOfServiceOn     : "December 4, 2022",
                daysOutOfService   : 32,
                expensesPercentage : 94.08,
                remainingAmount    : 880,
                remainingPercentage: 5.92
            },
            {
                id                 : 2,
                type               : 'CCGX3343',
                outOfServiceOn     : "December 21, 2022",
                daysOutOfService   : 12,
                expensesPercentage : 81.78,
                remainingAmount    : 3839.66,
                remainingPercentage: 18.22
            },
            {
                id                 : 3,
                type               : 'CCGX1322',
                outOfServiceOn     : "January 3, 2023",
                daysOutOfService   : 4,
                expensesPercentage : 10.13,
                remainingAmount    : 31202,
                remainingPercentage: 89.87
            }
        ]
    },
    teamMembers       : [
        {
            id    : '2bfa2be5-7688-48d5-b5ac-dc0d9ac97f14',
            avatar: 'assets/images/avatars/female-10.jpg',
            name  : 'file.txt',
            email : 'nadiamcknight@mail.com',
            phone : '+1-943-511-2203',
            title : 'Logs'
        },
        {
            id    : '77a4383b-b5a5-4943-bc46-04c3431d1566',
            avatar: 'assets/images/avatars/male-19.jpg',
            name  : 'file2.xlsx',
            email : 'blackburn.best@beadzza.me',
            phone : '+1-814-498-3701',
            title : 'Datasheet'
        },
        {
            id    : '8bb0f597-673a-47ca-8c77-2f83219cb9af',
            avatar: 'assets/images/avatars/male-14.jpg',
            name  : 'file3.docx',
            email : 'duncancarver@mail.info',
            phone : '+1-968-547-2111',
            title : 'System Logs'
        },
        {
            id    : 'c318e31f-1d74-49c5-8dae-2bc5805e2fdb',
            avatar: 'assets/images/avatars/male-01.jpg',
            name  : 'file4.docx',
            email : 'martinrichards@mail.biz',
            phone : '+1-902-500-2668',
            title : 'Inspection January'
        },
        {
            id    : '0a8bc517-631a-4a93-aacc-000fa2e8294c',
            avatar: 'assets/images/avatars/female-20.jpg',
            name  : 'file5.docx',
            email : 'candicemunoz@mail.co.uk',
            phone : '+1-838-562-2769',
            title : 'Inspection February'
        },
        {
            id    : '0a8bc517-631a-4a93-aacc-000fa2e8294c',
            avatar: 'assets/images/avatars/female-20.jpg',
            name  : 'file5.docx',
            email : 'candicemunoz@mail.co.uk',
            phone : '+1-838-562-2769',
            title : 'Inspection March'
        },
        {
            id    : '0a8bc517-631a-4a93-aacc-000fa2e8294c',
            avatar: 'assets/images/avatars/female-20.jpg',
            name  : 'file5.docx',
            email : 'candicemunoz@mail.co.uk',
            phone : '+1-838-562-2769',
            title : 'Inspection April'
        },
        {
            id    : '0a8bc517-631a-4a93-aacc-000fa2e8294c',
            avatar: 'assets/images/avatars/female-20.jpg',
            name  : 'file5.docx',
            email : 'candicemunoz@mail.co.uk',
            phone : '+1-838-562-2769',
            title : 'Inspection May'
        },
    ],

    inspections: [
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            inspectionId: '528651571NT',
            locomotive         : 'CCGX4000',
            amount       : +1358.75,
            status       : 'completed',
            date         : '2019-10-07T22:22:37.274Z'
        },
        {
            id           : '2dec6074-98bd-4623-9526-6480e4776569',
            inspectionId: '421436904YT',
            locomotive         : 'CCGX4000',
            amount       : -1042.82,
            status       : 'completed',
            date         : '2019-12-18T14:51:24.461Z'
        },
        {
            id           : 'ae7c065f-4197-4021-a799-7a221822ad1d',
            inspectionId: '685377421YT',
            locomotive         : 'CCGX5402',
            amount       : +1828.16,
            status       : 'pending',
            date         : '2019-12-25T17:52:14.304Z'
        },
        {
            id           : '0c43dd40-74f6-49d5-848a-57a4a45772ab',
            inspectionId: '884960091RT',
            locomotive         : 'CCGX4320',
            amount       : +1647.55,
            status       : 'completed',
            date         : '2019-11-29T06:32:16.111Z'
        },
        {
            id           : 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
            inspectionId: '361402213NT',
            locomotive         : 'CCGX4200',
            amount       : -927.43,
            status       : 'completed',
            date         : '2019-11-24T12:13:23.064Z'
        }
    ],
    locomotives: [
        {
            name: 'CCGX4000',
            avatar: 'assets/images/locomotives/CCGX4000.jpg'
        },
        {
            name: 'CCGX5402',
            avatar: ''
        },
        {
            name: 'CCGX4320',
            avatar: ''
        },
        {
            name: 'CCGX4202',
            avatar: 'assets/images/locomotives/CCGX4202.jpg'
        },
        {
            name: 'CCGX5202',
            avatar: 'assets/images/locomotives/CCGX5202.jpg'
        },
        {
            name: 'CCGX5311',
            avatar: 'assets/images/locomotives/CCGX5311.jpg'
        },
    ],
};
