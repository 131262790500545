<h2 mat-dialog-title class="text-lg font-medium">User Settings</h2>

<form class="flex flex-col flex-auto overflow-y-auto" [formGroup]="settingsForm">

    <mat-dialog-content class="mat-typography">

        <!-- Inspection Item -->
        <div class="mt-2 h-[35px]">
            <mat-checkbox class="-left-[8px] pl-0" (change)='enabledChange($event)'
            formControlName="alertsEnabled">Due Inspection Alerts Enabled</mat-checkbox>
        </div>

        <!-- DaysBeforeAlert -->
        <div class="mt-2" *ngIf="daysBeforeAlertVisible()">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Days Before Alert</mat-label>
                <input matInput type="number" [formControlName]="'daysBeforeAlert'">
            </mat-form-field>
        </div>

        <div class="mt-4 h-[35px]">
            <mat-checkbox class="-left-[8px] pl-0" (change)='enabledChange($event)'
            formControlName="defectAlertsEnabled">Defect Alerts Enabled</mat-checkbox>
        </div>

        <!-- DaysBeforeDefectAlert -->
        <div class="mt-2" *ngIf="daysBeforeDefectAlertVisible()">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Days Before Defect Alert</mat-label>
                <input matInput type="number" [formControlName]="'daysBeforeDefectAlert'">
            </mat-form-field>
        </div>

        <div class="flex mt-6">
            <mat-icon [svgIcon]="'heroicons_outline:globe'"></mat-icon>
            <span class="ml-2">Sites</span>
        </div>

        <div formArrayName="alertSiteIds" class="mt-2">
            <ng-container *ngFor="let site of sites; let i = index">
                <div class="flex items-center leading-6 ml-4">
                    <mat-checkbox class="w-full" [formControlName]="i">{{site.name}}</mat-checkbox>
                </div>
            </ng-container>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [disabled]="loading || settingsForm.invalid" (click)="onSave()" cdkFocusInitial>Save</button>
    </mat-dialog-actions>

</form>