import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Site, Province } from 'app/modules/admin/sites/admin-sites.types';
import { environment } from 'environments/environment';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
    providedIn: 'root'
})
export class AdminSitesService
{
    // Private
    private _site: BehaviorSubject<Site | null> = new BehaviorSubject(null);
    private _sites: BehaviorSubject<Site[] | null> = new BehaviorSubject(null);
    private _userSites: BehaviorSubject<Site[] | null> = new BehaviorSubject(null);
    private _allSites: BehaviorSubject<Site[] | null> = new BehaviorSubject(null);
    private _provinces: BehaviorSubject<Province[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for site
     */
    get site$(): Observable<Site>
    {
        return this._site.asObservable();
    }

    /**
     * Getter for sites
     */
    get sites$(): Observable<Site[]>
    {
        return this._sites.asObservable();
    }

    /**
     * Getter for userSites
     */
    get userSites$(): Observable<Site[]>
    {
        return this._userSites.asObservable();
    }

    /**
     * Getter for countries
     */
    get provinces$(): Observable<Province[]>
    {
        return this._provinces.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get sites
     */
    getSites(): Observable<Site[]>
    {
        return this._httpClient.get<Site[]>(`${environment.apiUrl}/Sites`).pipe(
            tap((sites) => {
                sites.forEach(site => {
                    site.background = 'assets/images/cards/site-background.png';
                });
                this._sites.next(sites.sort((a, b) => a.province.localeCompare(b.province) || a.name.localeCompare(b.name)));
                this._allSites.next(sites.sort((a, b) => a.province.localeCompare(b.province) || a.name.localeCompare(b.name)));
            })
        );
    }

    getUserSites(user: AccountInfo): Observable<Site[]> {
        // Return sites for user
        return this._httpClient.get<Site[]>(`${environment.apiUrl}/Sites/GetByUserId/${user.idTokenClaims.oid}`)
        .pipe(tap((sites) => this._userSites.next(sites)));
    }

    clearSelectedUserSites(): void {
        this._userSites.next(null);
    }

    clearSites(): void {
        this._sites.next(null);
    }

    /**
     * Search sites with given query
     *
     * @param query
     */
    searchSites(query: string): Observable<Site[]>
    {
        let filtered: Site[] = [];

        // Filter the sites by name
        filtered = this._allSites.value.filter(site => site.name && site.name.toLowerCase().includes(query.toLowerCase()));
        filtered.sort((a, b) => a.province.localeCompare(b.province) || a.name.localeCompare(b.name));

        this._sites.next(filtered);
        return of(filtered);
    }

    /**
     * Get site by id
     */
    getSiteById(id: any): Observable<Site>
    {
        return this._httpClient.get<Site>(`${environment.apiUrl}/Sites/${id}`)
        .pipe(
            tap((site) => {
                site.background = 'assets/images/cards/site-background.png';
                this._site.next(site);
            })
        );
    }

    /**
     * Create Site
     */
    createSite(): Observable<Site>
    {
        let newSite = {
            id: 0,
            name: '',
            background: 'assets/images/cards/site-background.png',
        } as Site;

        this._site.next(newSite);
        
        return of(newSite);
    }

   
   /**
    * Save Site (DB)
    */
   persistSite(site: Site): Observable<Site>
   {
       return this._httpClient.post<Site>(`${environment.apiUrl}/Sites`, 
           {
               name: site.name,
               province: site.province
           }).pipe(
               map((newSite) => {
                   newSite.background = 'assets/images/cards/site-background.png';4

                   // Update the sites with the new site
                   var allSites = this._allSites.value;
                   
                   var updated = [newSite, ...allSites].sort((a, b) => a.province.localeCompare(b.province) || a.name.localeCompare(b.name));
                   
                   this._sites.next(updated);
                   this._allSites.next(updated);

                   // Return the new locomotive
                   return newSite;
               })
           );
   }

    /**
     * Update site
     *
     * @param site
     */
    updateSite(site: Site): Observable<Site>
    {
        return this._httpClient.put<Site>(`${environment.apiUrl}/Sites/${site.id}`, 
            {
                id: site.id,
                name: site.name,
                province: site.province,
            })
            .pipe(
                map((updatedSite) => {
                    
                    updatedSite.background = 'assets/images/cards/site-background.png';

                    // Update the sites with the new site
                    var allSites = this._allSites.value;

                    // Find the index of the updated site
                    const index = allSites.findIndex(item => item.id === updatedSite.id);
                    allSites[index] = updatedSite;

                    this._site.next(updatedSite);
                    this._sites.next(allSites);
                    this._allSites.next(allSites);

                    // Return the updated site
                    return updatedSite;
                })
            );

    }


    /**
     * Delete the site
     *
     * @param id
     */
    deleteSite(id: number): Observable<boolean>
    {
        return this.sites$.pipe(
            take(1),
            switchMap(sites => this._httpClient.delete(`${environment.apiUrl}/Sites/${id}`).pipe(
                map(() => {

                    // Find the index of the deleted site
                    const index = sites.findIndex(item => item.id === id);

                    // Delete the site
                    sites.splice(index, 1);

                    // Update the sites
                    this._sites.next(sites);
                    this._allSites.next(sites);

                    // Return the deleted status
                    return true;
                })
            ))
        );
    }

    /**
     * Get provinces
     */
    getProvinces(): Observable<Province[]>
    {
        return this._httpClient.get<any[]>(`${environment.apiUrl}/Sites/provinces`).pipe(
            map((result) => {
                return (result as any).provinces.map(elem => ({ name: elem } as Province));
            }),
            tap((provinces) => {
                this._provinces.next(provinces.sort((a, b) => a.name.localeCompare(b.name)));
            })
        );
    }

    /**
     * Update the avatar of the given site
     *
     * @param id
     * @param avatar
     */
    uploadAvatar(id: number, avatar: File): Observable<Site>
    {
        return this.sites$.pipe(
            take(1),
            switchMap(sites => this._httpClient.post<Site>(`${environment.apiUrl}/Sites/image`, {
                id,
                avatar
            }, {
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': avatar.type
                }
            }).pipe(
                map((updatedSite) => {

                    // Find the index of the updated site
                    const index = sites.findIndex(item => item.id === id);

                    // Update the site
                    sites[index] = updatedSite;

                    // Update the sites
                    this._sites.next(sites);
                    this._allSites.next(sites);

                    // Return the updated site
                    return updatedSite;
                }),
                switchMap(updatedSite => this.site$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the site if it's selected
                        this._site.next(updatedSite);

                        // Return the updated site
                        return updatedSite;
                    })
                ))
            ))
        );
    }
}


